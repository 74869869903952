
import { defineComponent } from "vue";
import DynamicSettingsFor from "@/views/settings/constituent/DynamicSettingsFor.vue";

export default defineComponent({
  name: "CardsSettings",
  components: {
    DynamicSettingsFor,
  },
  setup() {
    return {};
  },
});
